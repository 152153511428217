@font-face {
  font-family: "Chocolate Cookies";
  src: local("Chocolate Cookies"),
    url(./fonts/chocolatecookies.ttf) format("truetype");
}

@keyframes rgbtext {
  to {
    background-position: 200% center;
  }
}

.text-animation {
  background: linear-gradient(
    60deg,
    #82e2ff,
    #a277ff,
    #61ffca,
    #ffca85,
    #f694ff,
    #82e2ff
  );
  background-size: 200% auto;

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: rgbtext 3s linear infinite;
}

.chocolate-cookies-font {
  font-family: "Chocolate Cookies";
}
